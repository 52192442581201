<template >
  <div class="sub_tab_inn">
    <h3>History of Subscription/Payment</h3>
    <!-- Table -->
    <div class="table_sec">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Subscription ID</th>
              <th>Start Date</th>
              <th>Amount</th>
              <th>Status</th>
               <th>Payment Type</th>
              <th>view payment</th>
              <!-- <th align="center" class="text-center">Save to PDF</th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in SubscriptionList">
              <tr
                :key="item.subscription.id"
                :class="{ opened: opened.includes(item.subscription.id) }"
              >
                <td>{{ item.subscription.id }}</td>
                <td>
                  {{
                    formatDate(
                      item.subscription.subscriptionStartDate,
                      "DD/MM/YYYY"
                    )
                  }}
                </td>
                <td>${{ item.payments.amount }}</td>
                <td>{{item.subscription.subscriptionStatus}}</td>
                 <td>{{item.subscription.paymentGateway}}</td>
                <!-- <td v-if="item.subscription.subscriptionStatus == 1">
                  Expired
                </td>
                <td v-else-if="item.subscription.subscriptionStatus == 2">
                  Active
                </td>
                <td v-else-if="item.subscription.subscriptionStatus == 3">
                  Self Deactivated
                </td>
                <td v-else>Failed</td> -->
                <td @click="toggle(item.subscription.id)">
                  <div class="">
                    <button
                      class="
                        showPayment
                        btn btn-sm-2
                        text-success
                        btn-icon-sm
                        box-title
                      "
                      style="margin-right: 10px"
                    >
                      <i class="icon-feather-file-text"></i>
                    </button>
                  </div>
                </td>
                <!-- <td align="center">
                  <button
                    class="btn showPDF btn-sm-2 box-title"
                    @click="ViewPDF()"
                  >
                    <img src="/assets/img/pdf_icon.svg" alt="" />
                  </button>
                </td> -->
              </tr>
              <tr
                :key="item.subscription.id"
                v-if="opened.includes(item.subscription.id)"
              >
                <td colspan="6">
                  <table :ref="'title' + index" class="table">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Payment Datetime</th>
                        <th>Payment Amount</th>
                        <th>Payment Method</th>
                        <th>Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ item.payments.transactionId }}</td>
                        <td>
                          {{ formatDate(item.payments.transactionDateTime, "DD/MM/YYYY HH:mm:ss") }}
                        </td>
                        <td>{{ item.payments.amount }}</td>
                        <td>{{ item.payments.paymentMethod }}</td>
                        <td>{{ item.payments.paymentStatus }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

import { GET_SUBSCRIPTION_LIST } from "@/store/actionsType";
import moment from "moment";

export default {
  props: [],
  data() {
    return {
      opened: [],
      SubscriptionList: [],
    };
  },
  created: function () {
    this.getSubscriptionList();
  },
  computed: {},
  mounted() {},
  methods: {
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

    formatDate(inputDate, dateFormat) {
      var date = moment(inputDate);
      if (date.isValid()) {
        return moment(inputDate).format(dateFormat);
      } else {
        return "-";
      }
    },

    async getSubscriptionList() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(GET_SUBSCRIPTION_LIST)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          this.SubscriptionList = response.reverse();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    ToggleThis(index) {
      window.jQuery(this.$refs["title" + index]).toggle("show");
    },
    ViewPDF() {},
  },
};
</script>
<style scoped>
.hide {
  display: none;
}
.showPayment:hover::after {
  content: "Payment Info";
  margin-top: -20px;
  margin-left: -50px;
}
.showPDF:hover::after {
  content: "View PDF";
  margin-top: -20px;
  margin-left: -50px;
}
.table-plain tbody tr,
.table-plain tbody tr:hover,
.table-plain tbody td {
  background-color: transparent;
  border: none;
}
</style>